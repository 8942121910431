.button {
  font-family: $typography-button-txt-font-family !important;
  font-weight: $typography-button-txt-font-weight !important;
  font-size: 14px !important;
  line-height: $typography-button-txt-line-height !important;
  block-size: sizes($button-size-height) !important;
  padding-inline-start: sizes($button-padding-side) !important;
  padding-inline-end: sizes($button-padding-side) !important;
  border-radius: 5px !important;
  height: 50px !important;
  min-width: 160px;
  font-style: $typography-button-txt-font-style !important;

  &-primary {
    background-color: var(--colors-frozenberry) !important;
    color: var(--colors-white) !important;
    border: none !important;
  }

  &-transparent {
    height: auto !important;
    width: auto !important;
    min-width: auto !important;
    padding: 0 !important;
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
  }

  &-secundary {
    background-color: var(--colors-litberry) !important;
    color: var(--colors-white) !important;
    border: none !important;
  }

  &-tertiary {
    background-color: var(--colors-white) !important;
    border: 1.5px solid var(--colors-litberry) !important;
    color: var(--colors-litberry) !important;
  }

  &-delete {
    background-color: var(--colors-white) !important;
    border: 1.5px solid var(--colors-badberry) !important;
    color: var(--colors-badberry) !important;
    width: 35px;
    min-width: 35px;
    height: 50px !important;
    padding: 0 !important;
  }

  &--disabled {
    background-color: #b2b2b2 !important;
    color: var(--colors-white) !important;
    border: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
