.changeForgotPassword {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: white;
  .btn-custom--white {
    color: #5D30DD;
  }  
  .ant-alert-message {
    overflow-wrap: break-word;
  }
  &__header {
    position: relative;
    text-align: center;
    max-width: 400px;
    &Title {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 900;
      font-size: 30px;
      line-height: 36px;
      color: #ffffff;
      margin-bottom: 25px;
    }
    &Descrip {
      font-family: 'Lato';
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      color: #ffffff;
      max-width: 250px;
      margin: 50px auto 30px;
    }
  }
  &__form {
    max-width: 280px;
    width: 100%;
    .ant-col {
      width: 100%;
    }
    &TxtAcces {
      font-family: 'Lato';
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      letter-spacing: -0.02em;
      color: #ffffff;
      border-bottom: 2px solid;
      padding-bottom: 4px;
      max-width: fit-content;
      margin: 0 auto 30px;
    }
  }
  .btn-custom {
    width: 100%;
    &--secundary {
      margin-bottom: 20px;
      margin-top: 30px;
    }
    &--white {
      color: #5D30DD;
    }
  }
  
  .input-custom {
    .ant-input-suffix {
      height: 111%;
      background: $color-primary;
      width: 43px;
      display: flex;
      justify-content: center;
      margin: -2px -10px 0 0;
      .ant-input-password-icon {
        color: white !important;
      }
    }
  }
  @media screen and (max-width: $medium) {
    h1 {
      margin-top: 40px;
    }
  }
}

@media screen and (max-width: $small) {
  .changeForgotPassword {
    &__card {
      width: 300px;
      .ant-form-item-label {
        text-align: start;
      }
    }

    &__forgot_password {
      width: 250px !important;
    }

    .alert {
      margin-top: 20px;
    }
  }
}

@media screen and (min-width: $small) {
  .changeForgotPassword {
    &__card {
      width: 300px;
      .ant-form-item-label {
        text-align: start;
      }
    }

    .alert {
      margin-top: 20px;
    }
  }
}

@media screen and (min-width: $s-medium) {
  .changeForgotPassword {
    &__card {
      width: 350px;
      .ant-form-item-label {
        text-align: start;
      }
    }

    .alert {
      margin-top: 20px;
    }
  }
}

@media screen and (min-width: $medium) {
  .changeForgotPassword {
    &__card {
      width: 450px;
      .ant-form-item-label {
        text-align: start;
      }
    }

    .alert {
      margin-top: 20px;
    }
  }
}

@media screen and (min-width: $large) {
  .changeForgotPassword {
    &__card {
      width: 450px;
      .ant-form-item-label {
        text-align: start;
      }
    }

    .alert {
      margin-top: 20px;
    }
  }
}

@media screen and (min-width: $x-large) {
  .changeForgotPassword {
    &__card {
      width: 450px;
      .ant-form-item-label {
        text-align: start;
      }
    }

    .alert {
      margin-top: 20px;
    }
  }
}

@media screen and (min-width: $xx-large) {
  .changeForgotPassword {
    &__card {
      width: 450px;
      .ant-form-item-label {
        text-align: start;
      }
    }

    .alert {
      margin-top: 20px;
    }
  }
}
