.TabsClaimCoverages {
  .form-covertage {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .upload {
      width: 35%;
      margin-right: 10px;
    }
    .textArea {
      width: 60%;
			margin-top: 0;
			.anticon {
				margin-left: 20px;
			}
    }
	}
	.Layout {
		background: #F7F7F7 !important;
		box-shadow: 3.81501px 3.81501px 7.63002px rgba(0, 0, 0, 0.1);
		&__card {
			background: #5D30DD;
			box-shadow: 3.81501px 3.81501px 7.63002px rgba(0, 0, 0, 0.1);
			width: 50%;
			height: 100%;
			border-radius: 10px;
			padding: 10px;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			p{
				color: #ffffff;
				font-weight: 10px;
			}
			h6 {
				color: #ffffff;
				opacity: 0.15;
				font-size: 15px;
				margin-bottom: 10px;
			}
		}
	}
  textarea {
		border-radius: 4px;
		resize: none;
		margin-top: 10px;
	}	
	.content__Layout--head .upload button {
    height: 100px;
	}
}
