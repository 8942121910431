.LinesOfProduct {
  .card {
    width: 512.15px !important;
    height: 221.29px !important;

    &--body {
      padding: 20px;
      height: 100%;
      background-size: cover !important;
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: flex-start;
      background: linear-gradient(
        0deg,
        rgba(75, 58, 124, 0.55),
        rgba(75, 58, 124, 0.55)
      );
      p {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #ffffff;
        max-width: 320px;
      }
      .icons {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        h2 {
          margin: 0;
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 150%;
          color: #ffffff;
        }

        .anticon {
          color: #35d8d0;
          font-size: 16px;
          margin-right: 10px;
        }
      }
    }
  }
  .slick-cloned {
    margin-left: -15px;
    transition: 1s;
  }
  .slick-arrow {
    display: none;
  }
  .slick-slider {
    padding: 21px 40px;
  }
  .slick-active {
    position: relative;
    transition: 1s;
  }
  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    padding: 35px 0;
    min-height: 1px;
  }
  .slick-dots li button:before {
    font-size: 1px;
    opacity: 1;
    height: 10px;
    width: 10px;
    border: 2px solid #5d30dd;
    border-radius: 50%;
    color: white;
  }
  .slick-dots li.slick-active button:before {
    opacity: 1;
    color: #5d30dd;
    background-color: #5d30dd;
  }
  .slick-current {
    position: relative;
    transform: scale(1.2);
    transition: 1s;
    z-index: 5;
  }
  .table {
    padding: 30px;
    h3 {
      margin-left: 0px;
    }
    .ant-table-wrapper {
      max-width: 100%;
      margin-top: 70px;
    }
  }
  .btn--table,
  .btn--table:hover,
  .btn--table:active {
    background-color: transparent;
    border-color: transparent;
    color: #bdbdbd;
    font-size: 20px;
    font-weight: bold;
  }
  .ant-btn:focus {
    color: #bdbdbd;
    background: transparent;
    border-color: transparent;
  }
  .ant-table-thead > tr > th {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
    opacity: 0.35;
  }
  .ant-table-tbody > tr > td {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: #000000;
  }
}
