.loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.3;
}

.ant-modal-loader {
  .ant-modal-content {
    background-color: transparent;
    height: 100%;

    .ant-modal-body {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ant-modal-close-x {
      display: none;
    }
  }

  backdrop-filter: blur(3px);
  padding-bottom: 24px !important;
  height: 100% !important;
  left: 0 !important;
  background: rgba(255, 255, 255, 0.282) !important;
  top: 0 !important;
  width: 100% !important;
  margin: 0 !important;
  max-width: none !important;
  padding-bottom: 0 !important;

}