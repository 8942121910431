.DetailPolicy {
  min-height: calc(100vh - 86px);
  padding: 25px;
  max-width: 1200px;
  margin: 0 auto;
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
      .button {
        height: 50px;
        border-radius: 5px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #FFFFFF;
        border: none;
        min-width: 150px;
        &-secundary {
          background: #35D8D0;
        }
        .loader {
          &__img {
            width: 20px;
            height: 23px;
            filter: brightness(1000);
          }
        }
        &:hover , &:focus, &:active {
          background: #35D8D0;
          color: #FFFFFF;
        }
      }
    
  }
  .CardItemListRenovations {
    .buttons {
      display: none !important;
    }
  }
  &__container {
    display: flex;
    justify-content: space-between;
    .content {
      width: 70%;
      .card-codes {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10%;
        height: 43px;
        background: #FFFFFF;
        margin: 20px 0;
        border-radius: 10px;
        h3 {
          margin: 0 10px;
          span {
            font-weight: 400;
          }
        }
      }
    }
  }
  .buttons-head {
    display: none;
  }
  .card-des {
    width: 28.5%;
  }
  @media (max-width: $medium) {
    &__head {
      margin-bottom: 0;
      .button {
        display: none;
      }
    }
    .card-des {
      display: none;
    }
    .card-float {
      position: absolute;
      top: 200px;
      width: 100%;
      box-shadow: 1px 7px 22px rgba(0, 0, 0, 0.20);
      left: 0;
    }
    .buttons-head {
      display: flex;
      padding: 20px;
      justify-content: space-between;
      .button {
        height: 50px;
        padding: 0;
        border-radius: 5px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #FFFFFF;
        border: none;
        width: 49%;
        .loader {
          &__img {
            width: 20px;
            height: 23px;
            filter: brightness(1000);
          }
        }
        &-primary {
          background: #5D30DD;
        }
        &-secundary {
          background: #35D8D0;
        }
      }
    }
    padding: 0;
    h2 {
      margin-left: 20px;
      margin-bottom: 0;
    }
    &__container {
      display: flex;
      flex-direction: column;
      .content {
        width: 100%;
        .card-codes {
          height: auto;
          padding: 20px;
        }
        .CardRisk {
          height: auto;
          min-height: auto;
          &__flex {
            flex-direction: column;
            .box {
              width: 100% !important;
              margin-bottom: 20px;
              padding: 0 !important;
              &--medium {
                border: none;
              }
            }
          }
        }
      }
    }
  }
}