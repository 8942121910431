.CardRisk {
  min-height: 315px;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 20px;
  &__flex {
    display: flex;
    justify-content: space-between;
    margin-top: 26px;
    .serial {
      width: 100%;
      height: 43px;
      background: #F7F7F7;
      border-radius: 7px;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 150.19%;
        color: #4B3A7C;
      }
    }
    .box {
      width: 33.3%;
      &--left {
        padding-right: 40px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        .box-item {
          width: 100%;
        }
      }
      &--rigth {
        padding-left: 40px;
      }
      &--medium {
        border: 1px solid #B2B2B2;
        border-top: none;
        border-bottom: none;
        padding: 0 40px;
      }
      &-item {
        margin-bottom: 40px;
        img {
          margin-right: 5px;
          margin-top: -2px;
        }
        &:last-child {
          margin-bottom: 0;
        }
        .text {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 700;
          font-size: 15px;
          line-height: 150.19%;
          color: #4B3A7C;
        }
      }
    }
  }
}