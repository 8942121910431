.col-signup {
  &::before {
    content: "";
    // background: $color-primary;
    width: 100%;
    height: calc(100% - 80px);
    position: absolute;
    top: 40px;
    border-radius: 15px 0 0 15px;
    background: linear-gradient(
      274.08deg,
      rgba(93, 48, 221, 0.67) -2.97%,
      #7f14fc 56.11%
    );
    background-blend-mode: multiply;
  }
}
.col-login {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  &__welcome {
    max-width: 376px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 57px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    &Title {
      color: #4b3a7c;
      font-family: "Lato";
      font-style: normal;
      font-weight: 900;
      font-size: 30px;
      margin-bottom: 13px;
    }
    &Subtitle {
      color: #4b3a7c;
      font-family: "Lato";
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.02em;
      margin-bottom: 40px;
    }
    &Link {
      font-weight: bold;
      font-family: "Lato";
      font-size: 12px;
      line-height: 125.19%;
      text-align: center;
      color: $color-primary;
      border-bottom: 1px solid $color-primary;
      padding-bottom: 2px;
    }
    .btn-custom {
      width: 192px;
      margin-bottom: 25px;
    }
    &ForgotPassword {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      color: $color-primary;
    }
  }
}
.full-height {
  .logo-berry {
    display: none;
  }
  @media screen and (max-width: $medium) {
    height: auto;
    flex-direction: column !important;
    display: flex !important;
    .logo-berry {
      display: unset;
    }
    .cont-img {
      height: 100px;
    }
    .col-signup::before {
      position: absolute;
      height: 645px;
      top: -10px;
      border-radius: 15px;
    }
    .ant-col {
      max-width: 100%;
      width: 100% !important;
    }
    .col-login__welcome {
      display: none;
    }
  }
}