.TabsListRenovations {
  .ant-tabs {
    .ant-tabs-ink-bar {
      position: absolute;
      background: #340AAB;
      pointer-events: none;
    }
    .title {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #340AAB;
      span {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 150%;
        color: #B2B2B2;
      }
    }
  }
  .empty {
    ont-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 105%;
    text-align: center;
    background: linear-gradient(90deg, #00dbd0 2.5%, #7b3bf9 75.97%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media (max-width: $medium) {
    padding: 20px;
    &-z-1 {
      .ant-tabs-nav {
        position: relative;
        z-index: -1;
      }
    }
  }
}