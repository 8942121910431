.breadcrumb {
  &.ant-breadcrumb {
    padding: 0 35px;
    min-height: 35px;
    display: flex;
    align-items: center;
    span {
      font-size: 10px;
      line-height: 12px;
      letter-spacing: -0.02em;
      color: #000000;
      opacity: 0.5;
    }
  }
}
.ant-layout {
  &.section-dashboard {
    background: none !important;
    .site-layout {
      background: none !important;
    }
  }
}

// .sidebar {
//   &.ant-layout-sider {
//     background: #ffffff;
//     box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
//     max-width: 240px !important;
//     min-width: 240px !important;
//     width: 240px !important;
//     font-family: "Lato";
//     padding: 20px 13px;
//     border-radius: 0 10px 10px 0;
//   }

//   &__head {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     &__home {
//       figure {
//         margin-bottom: 0;
//         span {
//           font-weight: 500;
//           font-size: 12px;
//           line-height: 14px;
//           color: $color-primary;
//         }
//       }
//     }
//     &__state {
//       display: flex;
//       align-items: center;
//       font-weight: 500;
//       font-size: 10px;
//       line-height: 12px;
//       text-align: center;
//       color: $color-primary--dark;
//       &__circle {
//         background: #27ae60;
//         width: 10px;
//         height: 10px;
//         display: block;
//         border-radius: 50%;
//         margin-right: 5px;
//       }
//     }
//   }
//   &__date {
//     margin-top: 25px;
//     margin-bottom: 50px;
//     &Day {
//       font-family: "Lato";
//       font-style: normal;
//       font-weight: bold;
//       font-size: 20px;
//       line-height: 24px;
//       letter-spacing: -0.02em;
//       color: $color-primary;
//       margin-bottom: 5px;
//       padding-left: 16px;
//     }
//     .ant-picker {
//       width: 100%;
//     }
//   }
//   &__data {
//     padding: 16px;
//     &__request {
//       margin-bottom: 20px;
//       &Title {
//         font-weight: 500;
//         font-size: 10px;
//         line-height: 12px;
//         color: #1b1b1b;
//         opacity: 0.3;
//         margin-bottom: 5px;
//       }
//       &Hour {
//         font-style: normal;
//         font-weight: normal;
//         font-size: 10px;
//         line-height: 12px;
//         color: #000000;
//         opacity: 0.5;
//         margin-bottom: 10px;
//       }
//       &Actions {
//         a {
//           font-weight: 500;
//           font-size: 10px;
//           line-height: 12px;
//           &:first-child {
//             color: #219653;
//             margin-right: 17px;
//           }
//           &:last-child {
//             color: #eb5757;
//           }
//         }
//         &--seemore {
//           a {
//             font-weight: 500;
//             font-size: 10px;
//             line-height: 12px;
//             color: $color-primary !important;
//           }
//         }
//       }
//     }
//   }
// }
.section-dashboard {
  &__close-sidebar {
    position: absolute;
    top: 106px;
    left: 18px;
    z-index: 9;
    cursor: pointer;

    img {
      max-width: 22px;
      margin-right: 8px;
    }
    figure {
      margin-bottom: 0;
      span {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: $color-primary;
        transition: opacity 0.2s linear;
      }
    }
    &Close {
      span {
        opacity: 0;
      }
    }
  }
}
.sidebar {
  &.ant-layout-sider {
    background: #ffffff;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 200px !important;
    min-width: 200px !important;
    width: 200px !important;
    font-family: "Lato";
    padding: 20px 0;
    border-radius: 0 10px 10px 0;
    &.ant-layout-sider-collapsed {
      width: 0 !important;
      min-width: 0 !important;
      padding: 20px 0;
      flex: 0 !important;
      overflow: hidden;

      @media screen and (max-width: 3840px) {
        margin-right: 0;
      }
      @media screen and (max-width: 1440px) {
        margin-right: 15px;
      }
    }
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 21px;

    &__state {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 10px;
      line-height: 22px;
      text-align: center;
      color: $color-primary--dark;
      &__circle {
        background: #27ae60;
        width: 10px;
        height: 10px;
        display: block;
        border-radius: 50%;
        margin-right: 5px;
      }
    }
  }
  &__date {
    margin-bottom: 20px;
    &Day {
      font-family: "Lato";
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: $color-primary;
      margin-bottom: 5px;
      padding-left: 16px;
    }
    .ant-picker {
      width: 100%;
    }
    .calendar-sidebar .ant-picker-date-panel .ant-picker-content th {
      width: 26px !important;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 9px;
      line-height: 7px;
      text-align: center;
      color: rgba(0, 0, 0, 0.543337);
    }
    .calendar-sidebar .ant-picker-content td {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 8px;
      line-height: 7px;
      text-align: center;
      height: 24px;
      max-height: 24px;
    }
    .calendar-sidebar .ant-picker-cell-in-view {
      color: rgba(0, 0, 0, 0.867782);
    }
    .calendar-sidebar
      .ant-picker-cell-in-view.ant-picker-cell-selected
      .ant-picker-cell-inner {
      background: #5d30dd !important;
      border-radius: 50%;
    }
    .calendar-sidebar .ant-picker-content tr {
      height: 24px;
      max-height: 24px;
    }
    .calendar-sidebar
      .ant-picker-calendar-header
      .ant-picker-calendar-mode-switch {
      display: none !important;
    }
    .calendar-sidebar .ant-select-selector {
      border: none !important;
    }
    .calendar-sidebar .ant-select-selector span.ant-select-selection-item {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 12px;
      display: flex;
      align-items: center;
      letter-spacing: -0.02em;
      color: #4b3a7c;
    }
    .calendar-sidebar .ant-picker-calendar-header {
      flex-direction: row-reverse;
      padding: 12px 0 !important;
    }
    .calendar-sidebar
      .ant-picker-calendar-header
      .ant-picker-calendar-month-select {
      margin-left: 0;
    }
    .site-calendar-demo-card {
      background: #ffffff;
      border-radius: 0 0 20px 20px;
      overflow: hidden;
      margin: 16px 21px;
      table {
        height: auto;
      }
    }
    .ant-picker-cell-in-view.ant-picker-cell-today
      .ant-picker-cell-inner::before {
      border: 1px solid #4b3a7c !important;
      border-radius: 50%;
    }
  }
  &__data {
    padding: 16px;
    &__request {
      margin-bottom: 20px;
      &Title {
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #1b1b1b;
        opacity: 0.3;
        margin-bottom: 5px;
      }
      &Hour {
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 12px;
        color: #000000;
        opacity: 0.5;
        margin-bottom: 10px;
      }
      &Actions {
        a {
          font-weight: 500;
          font-size: 10px;
          line-height: 12px;
          &:first-child {
            color: #219653;
            margin-right: 17px;
          }
          &:last-child {
            color: #eb5757;
          }
        }
        &--seemore {
          a {
            font-weight: 500;
            font-size: 10px;
            line-height: 12px;
            color: $color-primary !important;
          }
        }
      }
    }
  }

  &__menu {
    border-right: 0 !important;
    margin: 0 0 0 -13px !important;
    &Item {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      display: flex !important;
      min-height: 40px;
      align-items: center;
      color: #6524e5 !important;
      padding-left: 39px !important;
      margin-bottom: 0 !important;
      &.ant-menu-item-selected {
        background-color: #6524e5 !important;
        color: white !important;
        width: calc(100% + 30px) !important;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.14);
        border-radius: 0 4px 4px 0;
        &::after {
          display: none;
        }
      }
    }
  }
  &__logout {
    margin: 28px 32px 0;
    display: block;
    font-size: 12.5px;
    color: #5d30dd;
    font-weight: 600;
    img {
      vertical-align: sub;
      margin-right: 16px;
    }
  }
}
