.CardPdf {
  width: 100%;
  background: #FFFFFF;
  border-radius: 10px;
  min-height: 527px;
  height: 527px;
  padding: 20px;
  position: relative;
  .sub-title {
    margin-bottom: 20px;
    font-weight: 700;
  }
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .sub-title {
      margin-bottom: 0;
    }
    button {
      display: none;
      @media (max-width: 768px) {
        display: block;
        border: none;
        background-color: transparent;
        padding: 0;
      }
    }
  }
  .thanks {
    height: calc(100% - 60px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    h4 {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 150%;
      color: #4B3A7C;
      span {
        font-weight: 700;
        background: linear-gradient(90deg, #00dbd0 2.5%, #7b3bf9 75.97%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  .drag {
    height: calc(100% - 60px);
    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: none;
      margin-bottom: 0px;
      p {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 15px;
        margin-bottom: 0;
        color: #B2B2B2;
      }
      button {
        border: none;
        background-color: transparent;
        box-shadow: none;
        padding: 0;
      }
    }
    .button {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 57px;
      background: #35D8D0;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #FFFFFF;
      border: none;
      img {
        margin-left: 20px;
      }
    }
    .ant-upload {
      height: 100%;
    }
    .button-drag {
      height: 100%;
      margin: auto;
      white-space: break-spaces;
      background: transparent;
      border: none;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 150%;
      color: #B2B2B2;
      text-align: left;
      box-shadow: none;
      .here {
        color: #5D30DD;
        margin-left: 5px;
      }
    }
  }
  .after-content {
    position: relative;
    height: 100%;
    .after-text {
      position: absolute;
      font-family: 'Roboto';
      z-index: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 150%;
      color: #B2B2B2;
      top: 43%;
      margin-left: 20px;
      span {
        color: #5D30DD;
      }
    }
  }
  .upload-pdf {
    position: relative;
    z-index: 1;
    width: 100%;
    border: none;
    background: rgba(255, 0, 0, 0) !important;
    height: 100%;
    border: 1px dashed #eaeaea;
    border-radius: 36px;
    cursor: pointer;
    font-size: 0px;
    margin: 0;
    opacity: 1;
    outline: 0 none;
    padding: 0;
    margin-bottom: 20px;
  }  
  .absolute {
    margin-bottom: 20px;
    position: relative;
    p {
      z-index: 0;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 150%;
      color: #B2B2B2;
      position: absolute;
      margin-bottom: 0;
      top: 20px;
      padding: 10px 15px;
      border-radius: 10px;
      left: 0px;
      border: 1px dashed #eaeaea;
      width: 100%;
      span {
        color: #5D30DD;
      }
    }
  }
  .input-pdf {
    position: relative;
    z-index: 1;
    margin-top: 20px;
    font-size: 0px;
    cursor: pointer;
    opacity: 1;
    border-radius: 6px;
    outline: 0 none;
    padding: 0px;
    width: 100%;
    background: transparent;
    border: 1px dashed #eaeaea;
    height: 45px;
    opacity: 0;
  }
}