.insuransers {
  .button-ecxel {
    display: flex;
    gap: 8px;
    align-items: center;
    img {
      width: 20px;
    }
  }
  &-head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    &-col {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 20px;
    }
    .button-clear {
      border-radius: 7px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 7px;
    }

    .agents__title {
      margin: 0;
    }
    .filters {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 20px;
      .select-general {
        width: 200px;
        .ant-select-selector {
          position: relative;
          background-color: #fff;
          border: 1px solid #d9d9d9;
          height: 40px;
          border-radius: 7px;
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          padding-top: 3px;
        }
      }
    }
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .ant-empty {
    display: none;
  }
  .button-calendar {
    border: none;
    border-bottom: 1px solid var(--colors-oldberry);
    height: 42px;
    color: var(--Oldberry, #b2b2b2);
    text-align: center;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    min-width: 270px;
    font-weight: 400;
    cursor: pointer;
    line-height: 150%;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      border: none;
    }
    .calendar {
      margin-right: 5px !important;
    }
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
  .search-line {
    margin: 7px 0;
    height: 43px;
    max-width: 400px;
    border: none;
    background: transparent;
    border-bottom: 1px solid var(--colors-oldberry);
    .ant-input {
      background: transparent;
    }
    .ant-btn {
      border: none;
      padding: 0 2px;
    }
  }

  th.ant-table-cell,
  td.ant-table-cell.item-name {
    color: #4b3a7c;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 150.187%;
  }

  th.ant-table-cell {
    font-size: 16px;
  }

  td.ant-table-cell.item {
    color: #4b3a7c;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      padding: 4px;
      border-radius: 5px;
      background-color: $color-primary;

      img {
        transform: translate(0px, -1px);
        margin-left: 8px;
        filter: brightness(100);
      }
    }
  }

  .ant-pagination {
    justify-content: flex-end;
    margin: 20px 0;
  }

  .icon-insurance {
    width: 70px;
  }
}

.ant-popover-content {
  .rdp-months {
    .rdp-day {
      height: 20px;
      width: 30px;
    }
    .rdp-caption {
      color: #4b3a7c;
      font-family: Lato;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: 150.5%;
    }
    svg.rdp-nav_icon {
      color: #35d8d0;
    }
    .rdp-head_row {
      span {
        color: #b2b2b2;
        text-align: center;
        font-family: Roboto;
        font-size: 8px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
    .rdp-cell {
      width: 20px;
      height: 24px;
      color: #4b3a7c;
      text-align: center;
      font-family: Roboto;
      font-size: 8px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .rdp-day_selected:not([disabled]),
    .rdp-day_selected:focus:not([disabled]),
    .rdp-day_selected:active:not([disabled]),
    .rdp-day_selected:hover:not([disabled]) {
      color: white;
      background-color: #5d30dd;
    }
  }
}
