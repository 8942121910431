.header {
  background: $color-primary !important;
  padding: 0 35px !important;
  height: 51px !important;
  line-height: 51px !important;
  &__brand {
    a {
      margin-right: 15px;
    }
  }
  &__actions {
    display: flex !important;
    justify-content: flex-end;
    align-items: center;

    .btn-custom {
      width: 160px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Lato';
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
      margin-right: 23px;
    }
  }
  .custom-trigger-btn {
    text-align: center;
    min-width: 38px;
    transition: background 0.2s linear;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-right: 10px;
    &.ant-dropdown-open {
      background-color: #340aab;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &__dropdown-custom {
    min-width: 174px !important;
    .ant-dropdown-menu {
      background-color: #340aab;
      font-family: 'Lato';
      font-weight: bold !important;
    }
    &__item {
      font-size: 12px !important;
      color: #fff !important;
      padding: 5px 20px !important;
      font-weight: bold !important;
      &--hasAfter {
        &::after {
          content: '';
          background: black;
          width: 100%;
          height: 1px;
          display: block;
          opacity: 0.2;
        }
      }
      &State {
        color: #fff;
        font-size: 12px;
        margin-bottom: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        &--label {
          color: #fff;
          font-size: 10px;
          margin-bottom: 0;
          text-align: center;
        }
        &__circle {
          background: #27ae60;
          width: 10px;
          height: 10px;
          display: block;
          border-radius: 50%;
          margin-right: 5px;
        }
      }
      &:hover {
        background: none !important;
      }
      &--logOut {
        margin-top: 40px !important;
        margin-bottom: 5px !important;
      }
    }
    &Notification {
      background-color: #340aab;
      font-family: 'Lato';
      font-weight: bold !important;
      min-width: 400px !important;
      color: #fff !important;
      font-size: 12px !important;
      .notifications-menu {
        padding: 25px 25px 10px;
        &__head {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 20px;
          &Title {
            margin-bottom: 0;
          }
          &Action {
            text-decoration: underline;
            margin-bottom: 0;
          }
        }
        &__body {
          padding-top: 15px;
          margin-bottom: 35px;
          &__notification {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 23px;
            &Detail {
              &__title {
                margin-bottom: 0;
                small {
                  font-weight: 300;
                }
              }
              &__descrip {
                margin-bottom: 0;
                font-weight: lighter;
              }
            }
            &Action {
              background: #ffffff;
              border: 1px solid $color-primary;
              box-sizing: border-box;
              border-radius: 5px;
              font-size: 12px;
              line-height: 14px;
              display: flex;
              align-items: center;
              text-align: center;
              justify-content: center;
              align-items: center;
              color: $color-primary;
              width: 94px;
              height: 40px;
            }
          }
        }
        &__footer {
          text-align: center;
          font-size: 13px;
          text-decoration: underline;
        }
      }
    }
  }
  @media screen and (max-width: 761px) {
    margin: 0 !important;
    .ant-col-xs-4, .ant-col-xs-20 {
      flex: auto; 
      max-width: none; 
    }
  }
}