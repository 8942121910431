.modal-styles {
  .button {
    height: 30px !important;
    min-width: 20px !important;

    &-primary {
      min-width: 80px !important;
    }

    &-edit,
    &-delete {
      padding: 5px 10px !important;
    }
  }
}

.modal-general {
  .flex {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-top: 20px;

    .button {
      width: 50%;
    }
  }

  .ant-select-selector {
    height: 50px !important;
    padding: 9px 11px !important;
    border-radius: 5px !important;
  }
  .table-list {
    margin-top: 30px;
    .row {
      display: flex;
      justify-content: space-between;
      margin-top: 5px;
      .col {
        width: 50%;
      }
    }
  }
  &-confirm {
    .ant-modal-body {
      padding: 30px 50px;
      h1 {
        margin-bottom: 30px;
      }
    }
  }
}
