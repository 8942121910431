.CardRenovations {
  width: 185px;
  height: 572px;
  border-radius: 10px;
  margin-right: 20px;
  .box {
    background: #1C0E42;
    height: 33%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &-top {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    &-bottom {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    &--medium {
      margin: 2px 0;
      border-radius: 0;
      padding: 10px;
      .card {
        width: 100%;
        height: 100px;
        background: #FFFFFF;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
      }
    }
    p {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 150.19%;
      text-align: center;
      color: #FFFFFF;
      margin-bottom: 0;
    }
    .title {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 80px;
      line-height: 105%;
      text-align: center;
      color: #EB5757;
      margin-bottom: 0;
      &-gradient {
        background: linear-gradient(90deg,#00dbd0 2.5%,#7b3bf9 75.97%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  @media (max-width: $medium) {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 118px;
    .box {
      width: 33.3%;
      height: 100%;
      &-top {
        border-top-left-radius: 10px;
        border-top-right-radius: 0px;
        border-bottom-left-radius: 10px;
      }
      &-bottom {
        border-bottom-left-radius: 0px;
        border-top-right-radius: 10px;  
        border-bottom-right-radius: 10px;  
      }
      p {
        font-size: 10px;
        line-height: 15px;
      }
      &--medium {
        margin: 0 1px;
        .card {
          width: 86px;
          height: 55px;
        }
      }
      .title {
        font-size: 50px;
        line-height: 105%;
      }
    }
  }
}