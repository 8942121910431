.TabsPermits {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 50px;
	h4 {
		font-family: Lato;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		line-height: 24px;
		color: #4B3A7C;
		margin-bottom: 40px;
	}
	&__card {
		background: #FFFFFF;
		border: 1px solid #e0e0e0;
		box-shadow: inset 20px 0px 34px rgba(165, 165, 165, 0.25);
		border-radius: 10px;
		padding: 35px;
		.head-title {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			.anticon{
				margin-right: 10px;
				font-size: 15px;
				cursor: pointer;
			}
			img {
				margin-top: -4px;
			}
		}
	}
	&--left {
		height: 100%;
		input.ant-input {
			background: #FFFFFF;
			border: 1px solid #BDBDBD;
			box-sizing: border-box;
			border-radius: 5px;
			height: 50px;
			margin: 10px 0;
		}
		h4 {
			margin-bottom: 10px;
		}
		.two-title {
			margin-top: 30px;
		}
	}
	.cards {
		display: flex;
		justify-content: flex-start;
    flex-wrap: wrap;
		width: 600px;
		padding: 0 20px;
	}
	.card {
		width: 117.72px;
		height: 50px;
		cursor: pointer;
		border: 2px solid #5D30DD;
		box-sizing: border-box;
		border-radius: 5px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 10px;
		img {
			height: 28px;
			width: 50px;
			object-fit: contain;
		}
	}
}
