.create-user {
  max-width: 380px;
  margin: 0 auto;
  &__title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #ffffff;
    margin-bottom: 35px;
  }
  &__inputs {
    .input-custom {
      background: transparent;
      display: flex;
      align-items: center;
      .ant-input-prefix {
        margin-right: 8px;
      }
    }
    .ant-input {
      padding-left: 15px !important;
      background: #ffffff;
      border-radius: 5px;
      width: 304.54px;
      height: 40px;
    }
  }
  &__button {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    padding-left: 4px;
    .btn-custom {
      width: 304.54px;
      height: 50px;
    }
  }
}
