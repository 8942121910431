// Headings

.h1 {
  @extend h1;
  color: var(--colors-pureberry);
  line-height: 60px;
  font-weight: 900;
  font-size: 50px;
}

.h1-color-gradient-berry {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 50px;
  line-height: 52px;
  background: linear-gradient(93deg, #00dbd0 2.5%, #7b3bf9 35.97%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.h2 {
  @extend h2;
  font-size: 30px;
  color: var(--colors-pureberry);
  line-height: 36px;
  font-weight: 900;
}

.h3 {
  @extend h3;
  font-size: 28px;
  color: var(--colors-pureberry);

  @media (max-width: $medium) {
    font-size: 20px;
  }
}

.h4 {
  @extend h4;
  font-size: 20px;
  color: var(--colors-pureberry);
}

.h5 {
  @extend h5;
  font-size: 17px;
  color: var(--colors-pureberry);
}

.h6 {
  @extend h6;
  font-size: 15ox;
  color: var(--colors-pureberry);
}

.sub-title {
  font-family: $typography-sub-title-font-family;
  font-weight: $typography-sub-title-font-weight;
  // line-height: $typography-sub-title-line-height;
  font-size: 20px;
  // letter-spacing: sizes($typography-sub-title-letter-spacing);
  color: var(--colors-pureberry);
  line-height: 24px !important;
  letter-spacing: -0.02em !important;
}

.body-bold {
  font-family: $typography-body-bold-font-family;
  font-style: $typography-body-bold-font-style;
  font-weight: $typography-body-bold-font-weight;
  font-size: 15px;
  // line-height: sizes($typography-body-bold-line-height);
  color: var(--colors-pureberry);
}

.body-regular {
  font-family: $typography-body-regular-font-family;
  font-style: $typography-body-regular-font-style;
  font-weight: $typography-body-regular-font-weight;
  line-height: 150% !important;
  font-size: 15px;
  color: var(--colors-pureberry);
}

.body-detail {
  font-family: $typography-small-detail-font-family;
  font-style: $typography-small-detail-font-style;
  font-weight: $typography-small-detail-font-weight;
  font-size: 10px;
  line-height: $typography-button-txt-line-height;
  color: var(--colors-pureberry);
}

.button-txt {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
}

.small-detail {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  color: #FFFFFF;
}