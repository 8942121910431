.login {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  .ant-alert-message {
    overflow-wrap: break-word;
  }
  .Loader {
    position: relative;
  }
  .card-login {
    padding: 20px 16px;
    padding-top: 28px;
    padding-bottom: 0;
    min-width: 290.43px;
    background: #ffffff;
    border-radius: 10px;
    position: relative;

    .red {
      width: 40px;
      margin: 0 10px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #5d30dd;
      border-radius: 50%;
      cursor: pointer;
      img {
        margin: 0 !important;
      }
    }
    .btn--login {
      position: absolute;
      margin-top: 10px;
    }
    .input-custom {
      border: 1px solid #bdbdbd !important;
      border-radius: 5px !important;
    }
    .ant-form-item-has-error .ant-input,
    .ant-form-item-has-error .ant-input-affix-wrapper,
    .ant-form-item-has-error .ant-input:hover,
    .ant-form-item-has-error .ant-input-affix-wrapper:hover {
      border-color: #ff4d4f !important;
    }
  }
  &__redes {
    position: relative;
    text-align: center;
    max-width: 280px;
    display: flex;
    flex-direction: column;
    width: 100%;
    .images {
      display: flex;
      justify-content: center;
    }
    &Title {
      font-family: "Lato";
      font-style: normal;
      font-weight: 900;
      font-size: 30px;
      line-height: 36px;
      color: #ffffff;
      margin-bottom: 25px;
      position: relative;
      text-align: center;
    }
    img {
      margin-right: 13px;
      cursor: pointer;
      &:nth-child(4) {
        margin-right: 0;
      }
    }
    &Separator {
      font-family: "Lato";
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: #b2b2b2;
      position: relative;
      margin-top: 25px;
      margin-bottom: 40px;
      text-transform: uppercase;
      &::before {
        content: "";
        position: absolute;
        border: 1px solid #f7f7f7;
        width: 45%;
        top: 50%;
        left: 0;
      }
      &::after {
        content: "";
        position: absolute;
        border: 1px solid #f7f7f7;
        width: 45%;
        top: 50%;
        right: 0;
      }
    }
  }
  &__form {
    max-width: 280px;
    width: 100%;
    .ant-col {
      width: 100%;
    }
    &TxtAcces {
      font-family: "Lato";
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      letter-spacing: -0.02em;
      color: #ffffff;
      border-bottom: 2px solid;
      padding-bottom: 4px;
      max-width: fit-content;
      margin: 0 auto 30px;
    }
  }
  .btn-custom {
    width: 107%;
    margin-left: -10px;
  }
  &__link-forgot-pass {
    font-family: "Lato";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #5d30dd;
    border-bottom: 2px solid;
    padding-bottom: 3px;
    max-width: fit-content;
    margin-bottom: 30px;
    display: block;
  }
  @media screen and (max-width: $medium) {
    h1 {
      margin-top: 40px;
    }
  }
}

@media screen and (max-width: $small) {
  .login {
    &__card {
      width: 300px;
      .ant-form-item-label {
        text-align: start;
      }
    }

    &__forgot_password {
      width: 250px !important;
    }

    .alert {
      margin-top: 20px;
    }
  }
  
}

@media screen and (min-width: $small) {
  .login {
    &__card {
      width: 300px;
      .ant-form-item-label {
        text-align: start;
      }
    }

    .alert {
      margin-top: 20px;
    }
  }
}

@media screen and (min-width: $s-medium) {
  .login {
    &__card {
      width: 350px;
      .ant-form-item-label {
        text-align: start;
      }
    }

    .alert {
      margin-top: 20px;
    }
  }
}

@media screen and (min-width: $medium) {
  .login {
    &__card {
      width: 450px;
      .ant-form-item-label {
        text-align: start;
      }
    }

    .alert {
      margin-top: 20px;
    }
  }
}

@media screen and (min-width: $large) {
  .login {
    &__card {
      width: 450px;
      .ant-form-item-label {
        text-align: start;
      }
    }

    .alert {
      margin-top: 20px;
    }
  }
}

@media screen and (min-width: $x-large) {
  .login {
    &__card {
      width: 450px;
      .ant-form-item-label {
        text-align: start;
      }
    }

    .alert {
      margin-top: 20px;
    }
  }
}

@media screen and (min-width: $xx-large) {
  .login {
    &__card {
      width: 450px;
      .ant-form-item-label {
        text-align: start;
      }
    }

    .alert {
      margin-top: 20px;
    }
  }
}
