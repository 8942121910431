.PiaaAccess {
  padding: 40px;
  padding-top: 20px;
  .input-upload {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    padding-top: 12px;
    margin: 0 auto;
    margin-top: 30px;
  }
  // input {
  //   background-color: red !important;
  // }
  .logo-ecxel {
    height: 30px;
    margin-left: 10px;
  }
  .button-ecxel {
    gap: 20px;
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon,
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background: #5d30dd;
    border-color: #5d30dd;
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #fff;
    border-color: #5d30dd;
  }
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #5d30dd;
  }
  .ant-steps {
    margin-bottom: 30px;
  }
  .ant-steps-item-title {
    font-weight: 600;
    font-family: "Lato";
    color: #5d30dd !important;
  }
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .column {
      width: 50%;
    }
  }
  .flex-btw {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }
  .page-progress {
    position: fixed;
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    backdrop-filter: blur(4px);
    background: rgba(255, 255, 255, 0.282) !important;
    .ant-progress {
      width: 400px;
      margin-top: 30px;
      .ant-progress-bg {
        height: 34px !important;
      }
    }
    .h2 {
      position: relative;
      z-index: 1;
    }
    .loader {
      height: 30px;
      width: auto;
    }
  }
  .form-items {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
    .item {
      width: 100%;
    }
  }
}
