.forgotPassword {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  .ant-alert-message {
    overflow-wrap: break-word;
  }
  .Loader {
    position: relative;
  }
  .item {
    padding: 20px;
    background-color: #ffffff;
    position: relative;
    border-radius: 10px;
    width: 290px;
  }
  .ant-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .ant-form-item {
    margin-bottom: 0px !important;
  }
  .input-custom {
    border: 1px solid #BDBDBD !important;
    border-radius: 5px !important;
  }
  .btn-custom {
    min-width: 130px;
  }
  &__redes {
    position: relative;
    text-align: center;
    max-width: 420px;
    &Title {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 900;
      font-size: 30px;
      line-height: 36px;
      color: #ffffff;
      margin-bottom: 25px;
    }
    img {
      margin-right: 13px;
      cursor: pointer;
      &:nth-child(4) {
        margin-right: 0;
      }
    }
    &Descrip {
      font-family: 'Lato';
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      color: #ffffff;
      // max-width: 215px;
      margin: 0px auto 10px;
    }
  }
  &__form {
    max-width: 100%;
    width: 100%;
    .ant-col {
      width: 100%;
    }
    &TxtAcces {
      font-family: 'Lato';
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      letter-spacing: -0.02em;
      color: #ffffff;
      border-bottom: 2px solid;
      padding-bottom: 4px;
      max-width: fit-content;
      margin: 0 auto 30px;
    }
  }
  .btn-custom {
    width: 100%;
    &--secundary {
      margin-bottom: 5px;
      margin-top: 5px;
    }
  }
  &__link-forgot-pass {
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
    border-bottom: 2px solid;
    padding-bottom: 3px;
    max-width: fit-content;
    margin-bottom: 30px;
    display: block;
  }
  @media screen and (max-width: $medium) {
    h1 {
      margin-top: 100px;
    }
  }
}

@media screen and (max-width: $small) {
  .forgotPassword {
    &__card {
      width: 300px;
      .ant-form-item-label {
        text-align: start;
      }
    }

    &__forgot_password {
      width: 250px !important;
    }

    .alert {
      margin-top: 20px;
    }
  }
}

@media screen and (min-width: $small) {
  .forgotPassword {
    &__card {
      width: 300px;
      .ant-form-item-label {
        text-align: start;
      }
    }

    .alert {
      margin-top: 20px;
    }
  }
}

@media screen and (min-width: $s-medium) {
  .forgotPassword {
    &__card {
      width: 350px;
      .ant-form-item-label {
        text-align: start;
      }
    }

    .alert {
      margin-top: 20px;
    }
  }
}

@media screen and (min-width: $medium) {
  .forgotPassword {
    &__card {
      width: 450px;
      .ant-form-item-label {
        text-align: start;
      }
    }

    .alert {
      margin-top: 20px;
    }
  }
}

@media screen and (min-width: $large) {
  .forgotPassword {
    &__card {
      width: 450px;
      .ant-form-item-label {
        text-align: start;
      }
    }

    .alert {
      margin-top: 20px;
    }
  }
}

@media screen and (min-width: $x-large) {
  .forgotPassword {
    &__card {
      width: 450px;
      .ant-form-item-label {
        text-align: start;
      }
    }

    .alert {
      margin-top: 20px;
    }
  }
}

@media screen and (min-width: $xx-large) {
  .forgotPassword {
    &__card {
      width: 450px;
      .ant-form-item-label {
        text-align: start;
      }
    }

    .alert {
      margin-top: 20px;
    }
  }
}
