.custom-imput {
  border-radius: 5px !important;
  border: 1px solid var(--Gray-4, #BDBDBD) !important;
  width: 100% !important;
  height: 49px;

  .ant-input-number-input-wrap {
    height: 49px;
    display: flex;
    align-items: center;
  }

  .ant-input-number-input {
    height: 49px;
    display: flex;
    align-items: center;
  }
}