.Renovations {
  min-height: calc(100vh - 86px);
  padding: 25px;
  max-width: 1200px;
  margin: 0 auto;
  .content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .content-rth {
    width: 100%;
  }
  @media (max-width: $medium) {
    padding: 20px 0;
    .content {
      flex-direction: column;
    }
  }
}