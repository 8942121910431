.ProductLine {
  display: flex;
  justify-content: center;
  .head {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px 30px;
    .left {
      display: flex;
      justify-content: center;
      align-items: center;
      h3 {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        letter-spacing: -0.02em;
        color: #5d30dd;
        margin: 0;
        margin-left: 20px;
      }
      .icon {
        height: 35px;
        width: 35px;
        border-radius: 50%;
        background-color: #4b3a7c;
        display: flex;
        justify-content: center;
        align-items: center;

        .anticon {
          color: #35d8d0;
          font-size: 15px;
        }
      }
    }
    .btn--secondary,
    .btn--secondary:hover,
    .btn--secondary:active {
      background: #35d8d0;
      border-color: #35d8d0;
      height: 40px;
      width: 40px;
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 17px;
      padding: 0px;
      color: #ffffff;
      border-radius: 50%;
    }
  }
  .Home {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    &__container {
      width: 100%;
      max-width: 1300px;
      background-color: #ffffff;
      border-radius: 10px;
    }
  }
}
