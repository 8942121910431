.change-password {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .ant-alert-message {
    overflow-wrap: break-word;
  }
}

@media screen and (max-width: $small) {
  .change-password {
    &__card {
      width: 300px;
      .ant-form-item-label {
        text-align: start;
      }
    }

    &__forgot_password {
      width: 250px !important;
    }

    .alert {
      margin-top: 20px;
    }
  }
}

@media screen and (min-width: $small) {
  .change-password {
    &__card {
      width: 300px;
      .ant-form-item-label {
        text-align: start;
      }
    }

    .alert {
      margin-top: 20px;
    }
  }
}

@media screen and (min-width: $s-medium) {
  .change-password {
    &__card {
      width: 350px;
      .ant-form-item-label {
        text-align: start;
      }
    }

    .alert {
      margin-top: 20px;
    }
  }
}

@media screen and (min-width: $medium) {
  .change-password {
    &__card {
      width: 600px;
      .ant-form-item-label {
        text-align: start;
      }
    }

    .alert {
      margin-top: 20px;
    }
  }
}

@media screen and (min-width: $large) {
  .change-password {
    &__card {
      width: 600px;
      .ant-form-item-label {
        text-align: start;
      }
    }

    .alert {
      margin-top: 20px;
    }
  }
}

@media screen and (min-width: $x-large) {
  .change-password {
    &__card {
      width: 600px;
      .ant-form-item-label {
        text-align: start;
      }
    }

    .alert {
      margin-top: 20px;
    }
  }
}

@media screen and (min-width: $xx-large) {
  .change-password {
    &__card {
      width: 600px;
      .ant-form-item-label {
        text-align: start;
      }
    }

    .alert {
      margin-top: 20px;
    }
  }
}
