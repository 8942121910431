.CardItemListRenovations {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: #FFFFFF;
  border-radius: 10px;
  box-shadow: -10px 7px 22px rgba(0, 0, 0, 0.02);
  margin-bottom: 10px;
  .box {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    &-left {
      padding: 20px;
    }
    .text {
      h3 {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #4B3A7C;
      }
      &-line {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .icon {
          margin-right: 10px;
          width: 12px;
          height: 10.8px;
        }
        p {
          margin-bottom: 0;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 150%;
          color: #4B3A7C;
        }
      }
      &-footer {
        display: flex;
        .flex {
          display: flex;
          margin-right: 30px;
          .sub-title, .item {
            font-family: 'Roboto';
            font-style: normal;
            font-size: 15px;
            margin-bottom: 0;
            line-height: 150.19%;
          }
          .sub-title {
            font-weight: 700;
            color: #4B3A7C;
          }
          .item {
            color: #4B3A7C;
            font-weight: 400;
            margin-left: 5px;
            &-error {
              color: #EB5757;
            }
          }
        }
      }
    }
    .image {
      width: 100px;
      margin-right: 21px;
      height: 89px;
      background: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: -10px 7px 22px rgba(0, 0, 0, 0.05);
      border-radius: 10px 10px 0px 0px;
    }
    &-items {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-start;
      margin-right: 20px;
      padding: 10px 0;
      h4 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 150.19%;
        color: #4B3A7C;
      }
      .flex {
        display: flex;
        gap: 10px;
        .item {
          width: 38px;
          height: 38px;
          background: #FFFFFF;
          box-shadow: -10px 7px 22px rgba(0, 0, 0, 0.05);
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          img {
            max-width: 33px;

          }
        }
      }
    }
    .buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      .button {
        height: 50%;
        width: 56px;
        border: none;
        box-shadow: none !important;
        &-eyes {
          border-bottom-right-radius: 10px;
        }
        &-stars {
          border-top-right-radius: 10px;
          border-bottom: 1px solid #e7e2e2;
        }
      }
    }
  }
  @media (max-width: $medium) {
    flex-direction: column;
    .box {
      flex-direction: column;
      .flex-column {
        flex-direction: column;
      }
      .image {
        background-color: none;
        padding: 0;
        height: auto;
        width: auto;
        box-shadow: none;
        margin-bottom: 21px;
      }
      .buttons {
        width: 100%;
        flex-direction: row !important;
        .button {
          width: 50%;
          position: inherit;
          height: 54px;
          &-stars {
            border-bottom: 0px solid #e7e2e2;
            border-right: 1px solid #e7e2e2;
            border-radius: 0px 0px 0px 10px;
          }
        }
      }
    }
    .box-items  {
      padding: 20px;
    }
  }
}