.Filters {
  .Filters-heads {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;
    .btn-filter {
      background: #ffffff;
      border-radius: 50px;
      height: 35px;
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 15px;
      color: #4b3a7c;
      border: none;
      .to {
        margin: 0 5px;
      }
      img {
        margin-left: 10px;
      }
    }
  }
  .container-filter {
    width: 100%;
    display: flex;
    background: #ffffff;
    border-radius: 10px;
    padding: 15px 20px;
    .section {
      margin-right: 10px;
      .head {
        button {
          display: none;
        }
      }
      &-head {
        display: flex;
        justify-content: space-between;
        .filters-buttons {
          gap: 10px;
          .btn {
            margin-left: 15px;
            padding: 0;
            border: none;
            background-color: transparent;
            .img {
              opacity: 0.1;
              &-down {
                transform: rotate(180deg);
              }
            }
            &--active {
              .img {
                opacity: 1;
              }
            }
          }
        }
      }
      &-medium {
        margin-right: 20px;
        max-width: 270px;
      }
      &-calendar {
        .rdp-day_selected:focus:not([disabled]) {
          border: none;
        }
        .rdp-row {
          .rdp-cell {
            &:last-child {
              button.rdp-button_reset.rdp-button.rdp-day.rdp-day_selected.rdp-day_range_middle {
                border-top-right-radius: 50% !important;
                border-bottom-right-radius: 50% !important;
              }
            }
            &:first-child {
              button.rdp-button_reset.rdp-button.rdp-day.rdp-day_selected.rdp-day_range_middle {
                border-top-left-radius: 50% !important;
                border-bottom-left-radius: 50% !important;
              }
            }
          }
        }
        width: 40%;
        .rdp-day_selected:not([disabled]),
        .rdp-day_selected:focus:not([disabled]),
        .rdp-day_selected:active:not([disabled]),
        .rdp-day_selected:hover:not([disabled]) {
          color: #ffffff !important;
          background-color: #5d30dd;
        }
        .rdp.rdp-multiple_months {
          width: auto;
          margin: 0;
        }
        .rdp-head {
          th.rdp-head_cell {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 8px;
            line-height: 9px;
            text-align: center;
            color: #b2b2b2;
          }
        }
        .rdp-tbody {
          .rdp-cell {
            width: 15px;
            height: 32px;
          }
          .rdp-day {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 8px;
            line-height: 9px;
            text-align: center;
            color: #4b3a7c;
            width: 25px;
            height: 25px;
          }
        }
        .rdp-month {
          margin: 0 0.4em;
        }
        .rdp-caption_label {
          font-family: "Lato";
          font-style: normal;
          font-weight: 700;
          font-size: 10px;
          line-height: 15px;
          color: #4b3a7c;
        }
        svg {
          path {
            color: #35d8d0;
          }
        }
      }
      .title {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 150.19%;
        color: #4b3a7c;
      }
      .buttons {
        display: flex;
        gap: 5px;
        justify-content: center;
        flex-wrap: wrap;
        .btn {
          width: 49%;
          height: 83px;
          border: 1px solid #f7f7f7;
          border-radius: 5px;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 700;
          font-size: 15px;
          line-height: 150.19%;
          color: #4b3a7c;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-transform: capitalize;
          img {
            margin-bottom: 8px;
          }
          &--active {
            border: 1px solid #340aab;
          }
        }
        &__big,
        &__small {
        }
        &__big {
          display: flex;
          justify-content: center;
          flex-direction: column;
          gap: 5px;
          width: 147px;
          button {
            width: 147px;
            height: 128px;
          }
        }
        &__small {
          gap: 5px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          width: 147px;
          button {
            width: 147px;
            height: 83.67px;
          }
        }
      }
      .item {
        input {
          border: 1px solid #bdbdbd;
          border-radius: 5px;
          height: 50px;
          box-shadow: none;
          width: 100%;
        }
        .ant-input-number {
          width: 100%;
          border: none;
          box-shadow: none;
          .ant-input-number-handler-wrap {
            display: none;
          }
        }
        .between {
          display: flex;
          justify-content: space-between;
          .btn {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 700;
            font-size: 15px;
            line-height: 150.19%;
            color: #5d30dd;
            padding: 5px 0;
            border: none;
            box-shadow: none;
            opacity: 0.2;
            &--active {
              opacity: 1;
            }
          }
        }
        &--covertage {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 100px;
          border-top: 1px solid #f7f7f7;
          border-bottom: 1px solid #f7f7f7;
          margin: 25px 0;
          p {
            margin-bottom: 0;
          }
          .btns {
            .btn {
              font-family: "Roboto";
              font-style: normal;
              font-weight: 700;
              font-size: 15px;
              line-height: 150.19%;
              color: #340aab;
              padding: 0px;
              margin-left: 10px;
              text-transform: capitalize;
              border: none;
              background-color: transparent;
              box-shadow: none;
              opacity: 0.2;
              &--active {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: $medium) {
    .Filters-heads {
      margin-top: 20px;
      overflow-x: auto;
      height: 70px;
      margin-left: 10px;
    }
    .container-filter {
      flex-direction: column;
      position: absolute;
      z-index: 300;
      top: 51px;
      box-shadow: 1px 7px 22px rgba(0, 0, 0, 0.2);
      .section {
        width: 100%;
        .head {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          margin-bottom: 20px;
          .title {
            margin-bottom: 0;
            margin-top: 3px;
          }
          .button-close {
            display: block;
            padding: 0;
            border: 0;
            background-color: transparent;
            font-family: "Lato";
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            color: #5d30dd;
            box-shadow: none;
            margin-bottom: 40px;
            img {
              margin-left: 10px;
            }
          }
        }
        .rdp-table {
          margin: 0;
          max-width: 100%;
          width: 100%;
          border-collapse: collapse;
        }
        .rdp-day {
          width: 100% !important;
        }
        .rdp-months {
          display: flex;
          flex-direction: column;
        }
      }
      .section-medium {
        max-width: 100%;
        margin: 0;
      }
    }
  }
}
