.new-product {
    .btn--cancel , .btn--cancel:hover , .btn--cancel:active , .btn--cancel:focus {
		background-color: transparent;
		border-color: transparent;
		font-family: Lato;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		color: #EB5757;
		margin-right: 20px;
	}
	.btn {
		&--secondary {
			border-radius: 5px !important;
			width: 100px !important;
			font-size: 14px !important;
		}
	}
	.content {
		padding: 30px;
		.ant-tabs-tab.ant-tabs-tab-active {
			border-bottom: 3px solid #5D30DD;
			z-index: 2;
		}
		.ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before {
			border-bottom: 1px solid #aa92ed;
		}
		.ant-tabs-tab {
			padding: 12px 10px;
		}
		.ant-tabs { 
			font-family: Roboto;
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			line-height: 14px;
			color: #aa92ed;
		}
		.ant-tabs-tab:hover {
			color: #5D30DD;
		}
		.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
			color: #5D30DD;
		}
		
	}
}