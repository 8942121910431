.HeaderFilters {
  width: 100%;
  margin-bottom: 20px;
  &__container {
    width: 100%;
    &__head {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .h2 {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 900;
        font-size: 30px;
        line-height: 36px;
        color: #4B3A7C;
        margin-bottom: 0;
      }
      .btn-filter {
        background: linear-gradient(274.08deg, rgba(93, 48, 221, 0.67) -2.97%, #7F14FC 56.11%);
        border-radius: 50px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #FFFFFF;
        height: 37px;
        &:hover, &:active, &:focus {
          color: #FFFFFF;
          background: linear-gradient(274.08deg, rgba(93, 48, 221, 0.67) -2.97%, #7F14FC 56.11%);
          box-shadow: none;
        }
        img {
          margin-left: 10px;
        }
      }
    }
  }
  @media (max-width: $medium) {
    padding: 0 20px;
    &__container__head .btn-filter {
      span {
        display: none;
      }
      img {
        margin: 0;
      }
    }
  }
}