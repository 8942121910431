.h2 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;
  color: #4b3a7c;
}
.sub-title {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #4b3a7c;
}
.h3 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: #4b3a7c;
}
.small-detail {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  color: #b2b2b2;
}
.body-regular {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 150.19%;
  color: #4b3a7c;
}

.full-height {
  height: 100%;
}

.modal-loader {
  .ant-modal-content {
    background-color: #ffffff00 !important;
    box-shadow: none !important;
  }

  .ant-modal-footer {
    display: none;
  }

  .ant-modal-close {
    display: none;
  }

  .ant-modal-body {
    display: flex;
    justify-content: center;
  }
}
.mar-0 {
  @media (max-width: $medium) {
    .ant-layout-content {
      margin: 0 !important;
    }
    .section-dashboard__close-sidebar {
      display: none;
    }
    .ant-layout-sider {
      display: none !important;
    }
  }
}
.btn-custom {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  border-radius: 5px;
  height: 50px;
  border: none;
  appearance: none;
  &--primary {
    background: $color-primary;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
  }
  &--secundary {
    background: $bg-color-btn-secundary;
  }
}
.input-custom {
  background: #ffffff;
  border-radius: 2px !important;
  height: 40px;
  border: none !important;
  color: #bfbfbf !important;
  input {
    font-family: "Lato";
  }
  .ant-input-prefix {
    margin-right: 13px;
  }
  &--phone {
    .ant-input-prefix {
      transform: rotate(90deg);
    }
  }
}
.ant-form-item-explain,
.ant-form-item-extra {
  font-size: 11px !important;
}
.ant-form-item {
  margin-bottom: 20px !important;
}
.table-dropdown-custom {
  min-width: 140px !important;
  background: #f7f7f7;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
  .ant-dropdown-menu {
    background: #f7f7f7;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 0;
  }
  .ant-dropdown-menu-item {
    min-height: 40px;
    display: flex;
    align-items: center;
    padding: 0 0 0 17px;
    a {
      font-family: "Lato";
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.02em;
      color: #5d30dd;
      &:hover {
        color: white;
      }
    }
    &:hover {
      background-color: #340aab;
      color: white;
      a {
        color: white;
      }
    }
  }
}
.modal-create-user {
  .ant-modal-content {
    background: #5d30dd;
    border-radius: 15px;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-modal-body {
    padding: 53px 0;
  }
  .ant-modal-close-x {
    display: none;
  }
}
.btn-custom--white {
  color: #340aab;
}
.btn--secondary {
  background: #35d8d0;
  border-color: #35d8d0;
  height: 40px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}

.ant-message {
  .ant-message-notice-content {
    max-width: 550px;
    width: 100%;
    padding: 0 !important;
    font-family: "Roboto";
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 130.69%;
    text-align: center;
    color: #ffffff;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15) !important;
  }
  .ant-message-custom-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    min-height: 60px;
    width: 100%;
    max-width: 900px;
    span {
      font-size: 17px;
      line-height: 19px;
    }
    .anticon {
      &::before {
        content: "";
        position: absolute;
        background: white;
        width: 90%;
        height: 90%;
        top: 50%;
        left: 50%;
        display: block;
        z-index: 0;
        border-radius: 100%;
        transform: translate(-50%, -50%);
      }
      svg {
        position: relative;
      }
    }
  }
  .ant-message-success {
    background: #47d8d0;
    .anticon {
      color: #47d8d0;
      font-size: 24px;
    }
  }
  .ant-message-error {
    background: #eb5757;
    .anticon {
      color: #eb5757;
      font-size: 24px;
    }
  }
}

//pagination

ul.ant-pagination.ant-table-pagination.ant-table-pagination-right {
  display: none;
}
.site-layout-background {
  @media screen and (max-width: 761px) {
    margin: 0 !important;
  }
}

.flex-end {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
}

.select-current {
  width: 300px;
}
.flex-table {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  margin-top: 10px;
  .ant-table-wrapper {
    width: 100%;
  }
  .left {
    width: 300px;
    .body-bold {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .button-primary {
    margin-top: 20px;
  }
  .ant-btn[disabled],
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:active {
    color: rgba(0, 0, 0, 0.25) !important;
    border-color: #d9d9d9 !important;
    background: #f5f5f5 !important;
    text-shadow: none;
    box-shadow: none;
  }
}
.input-amount {
  width: 100% !important;
  .ant-input-number-handler-wrap {
    display: none;
  }
}
.table-box {
  width: 100%;
}
