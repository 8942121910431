.agents {
  width: 100%;
  height: 100%;
  padding: 20px 20px 20px 45px;
  border-radius: 20px 0 0 20px;
  background-color: white;
  .ant-pagination-options {
    display: none !important;
    .ant-select-selector {
    }
  }
  .ant-pagination {
    display: flex;
    justify-content: center;
  }
  .table-cell, .ant-table-cell label, .table-cell--name {
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 15px !important;
    line-height: 150% !important;
    color: #4B3A7C !important;
  }
  .table-cell--email {
    color: #0021FF !important;
  }
  &__title {
    font-family: "Lato";
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #5d30dd;
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 39px;
    img {
      margin-right: 15px;
    }
  }
  &__head {
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
    &__open-modal {
      cursor: pointer;
    }
  }
  &__tabs {
    .ant-pagination {
      margin-top: 50px;
    }
    // .ant-pagination-next .ant-pagination-item-link {
    //   border-radius: 50%;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    // }
    .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .ant-pagination-item-active {
      font-weight: 500;
      background: #5d30dd;
      border-color: #5d30dd;
    }
    .ant-pagination-item-active a {
      color: #ffffff;
    }
    .ant-pagination-item {
      border-radius: 50%;
    }
    .ant-tabs-tab.ant-tabs-tab-active {
      opacity: 1;
    }

    .ant-tabs-top > .ant-tabs-nav,
    .ant-tabs-bottom > .ant-tabs-nav,
    .ant-tabs-top > div > .ant-tabs-nav,
    .ant-tabs-bottom > div > .ant-tabs-nav {
      margin: 0 0 21px 0;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #5d30dd;
    }
    .ant-tabs-tab {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #5d30dd;
      opacity: 0.55;
      margin-bottom: 10px;
    }
    .ant-tabs-tab-disabled {
      color: #5d30dd !important;
      opacity: 0.55;
    }
    .ant-tabs-tab:hover {
      color: #5d30dd;
      opacity: 1;
    }
    .ant-tabs-ink-bar {
      background: #4b3a7c;
      opacity: 0.75;
      height: 1px !important;
    }
    .ant-tabs-nav {
      &::before {
        border-bottom: 1px solid #5d30dd;
        opacity: 0.25;
        border: 0.5px solid #5d30dd;
      }
    }
    .table-agents {
      .ant-table-thead > tr > th {
        color: rgba(0, 0, 0, 0.35);
        font-weight: 500;
        text-align: left;
        background: #ffffff;
        border-bottom: none;
        font-size: 12px;
        line-height: 14px;
        &:nth-of-type(1) {
          padding: 16px 16px 16px 0;
        }
      }
      .ant-table-cell {
        img {
          margin-left: 7px;
        }
      }
      .table-cell {
        font-family: "Lato";
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: #000000;
        &--name {
          margin-left: -16px;
          img {
            margin-right: 10px;
          }
        }
        &--email {
          text-decoration-line: underline;
          color: #0021ff;
        }
      }
      .custom-trigger-btn {
        color: #fff;
        width: 95px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        font-family: Lato;
        background: #5D30DD;
        border-radius: 5px;
        span {
          margin-left: 15px;
        }
      }
      .ant-pagination-item {
        border-radius: 100%;
        border-color: rgba(93, 48, 221, 0.5);
        a {
          color: #4b3a7c;
        }
      }
      .ant-pagination-item-active {
        background: #5d30dd;
        opacity: 0.85;
        a {
          color: white;
        }
      }
      .ant-pagination-item-link {
        border-radius: 100%;
        border-color: rgba(93, 48, 221, 0.5);
      }
      .button-action {
        background: #5d30dd;
        width: 46%;
        height: 40px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-family: "Lato";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #ffffff;
        &:first-child {
          margin-right: 13px;
        }
      }
    }
    .actions-head {
      display: flex;
      justify-content: space-between;
      padding: 15px 50px 20px 0;
      &__select {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &Label {
          font-family: Lato;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 14px;
          color: #000000;
          opacity: 0.35;
          margin-right: 10px;
        }
        .ant-select-selector {
          border: 0.5px solid #4b3a7c8c !important;
          box-sizing: border-box;
          border-radius: 2px !important;
          height: 20px !important;
          width: 60px !important;
          padding: 0 !important;
          input {
            height: 20px !important;
          }
          span {
            line-height: 18px !important;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            color: #000000;
            margin-left: 3px;
            margin-bottom: 1px;
          }
        }

        .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
        .ant-select-single.ant-select-show-arrow
          .ant-select-selection-placeholder {
          padding-right: 0 !important;
        }

        .ant-select-arrow {
          width: 10px;
          height: 5px;
          right: 7px;
          svg {
            fill: #4b3a7c8c;
          }
        }
      }
      &__search {
        max-width: 480px;
        display: flex;
        align-items: center;
        figure {
          margin-bottom: 0;
          margin-left: 10px;
        }
        &Input {
          border: 0.5px solid #4b3a7c8c !important;
          box-sizing: border-box;
          border-radius: 5px !important;
          height: 40px !important;
        }
      }
    }
  }
  &__agentType,
  .ant-btn {
    &--selected {
      border-color: #40a9ff !important;
      color: #40a9ff !important;
    }

    &--notSelected {
      border-color: #d9d9d9 !important;
      color: rgba(0, 0, 0, 0.65) !important;
    }
  }
  @media screen and (max-width: 761px) {
    padding: 20px;
  }
}
