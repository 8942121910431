.TabsBase {
	h2 {
		font-family: Lato;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		line-height: 24px;
		color: #4B3A7C;
	}
	.content__Layout--head .upload button {
    width: 100%;
	}
  .cont-here {
		width: 100%;
		.head-title {
			display: flex;
			justify-content: space-between;
			width: 100%;
			span {
				font-family: Lato;
				font-style: normal;
				font-weight: bold;
				font-size: 10px;
				line-height: 15px;
				color: #B2B2B2;
				margin-left: 10px;
			}
		}
		.ant-upload {
			width: 100%;
		}
		.ant-switch-handle {
			top: -2px;
			width: 22px;
			height: 22px;
			&::before {
				background-color: #35D8D0;
				border-radius: 50%;
			}
		}	
		.ant-switch {
			height: 19px;
			background: #340AAB;
		}
	}
	.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
		border-radius: 3px;
		padding-top: 3px;
	}
}
