.NewLine , .TabsBase{
  .btn--cancel , .btn--cancel:hover , .btn--cancel:active , .btn--cancel:focus {
		background-color: transparent;
		border-color: transparent;
		font-family: Lato;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		color: #EB5757;
		margin-right: 20px;
	}
	.btn{
		&--secondary {
			border-radius: 5px !important;
			width: 100px !important;
			font-size: 14px !important;
		}
	}
	.content {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		width: 100%;
		padding: 50px;

		h4 {
			font-family: Lato;
			font-style: normal;
			font-weight: bold;
			font-size: 14px;
			line-height: 17px;
			color: rgba(51, 51, 51, 0.75);
		}
		.right {
			border-left: 1px solid #E5E5E5;
		}
		&__Layout {
			width: 50%;
			height: 100%;
			padding: 40px;
			.Layout {
				height: 200px;
				width: 100%;
				background: rgba(75, 58, 124, 0.55);
				border-radius: 10px;
				margin-top: 25px;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					border-radius: 10px;
				}
			}
			&--head {
				display: flex;
				justify-content: space-between;
				.upload {
					button {
						background: #E5E5E5;
						border-radius: 10px;
						display: flex;
						width: 198.41px;
						height: 90px;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						font-family: Roboto;
						font-size: 8px;
						margin-top: 10px;
						color: rgba(51, 51, 51, 0.75);
						span {
							margin: 0;
						}
						img {
							margin-bottom: 15px;
						}
					}
				}
			}
			&--titles {
				display: flex;
				width: 100%;
				justify-content: space-between;
				margin-top: 30px;
				.ant-input {
					background: #FFFFFF;
					border: 1px solid #E5E5E5;
					box-sizing: border-box;
					border-radius: 5px;
					width: 203.2px;
					height: 40px;
				}
			}
			.textArea {
				margin-top: 30px;
			}
		}
	}
}